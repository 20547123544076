import Modal from 'components/global/Modal'
import Resource from 'components/global/Resource'
import SectionTitle from 'components/global/SectionTitle'
import { Col, Row, Section } from 'components/grid'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { AcceleratorBasketQueryEdge, AcceleratorToolkitQueryEdge, extractColorLevel, toolkitProperties } from 'pages/intervention'
import React, { useState } from 'react'

interface Props {
	level: 'elementary' | 'secondary'
	wideReading: AcceleratorBasketQueryEdge
	toolkits: AcceleratorToolkitQueryEdge
}

export default function Accelerator({ level, wideReading, toolkits }: Props) {
	const [isModalOpen, setIsModalOpen] = useState(false)
	return (
		<Section margin="mt-32" scrollMarginTop="75px" id={level}>
			<Row center>
				<Col>
					<div className="mx-auto mb-10 max-w-3xl text-center">
						<h2 className="mb-2 text-5xl capitalize text-ab-100 md:text-8xl">{level}</h2>
					</div>
				</Col>
			</Row>
			<SectionTitle
				title={[
					<span key={Math.random()}>
						<span>ARC Accelerator™</span>
						<br />
						<span className="capitalize">{level} Grades</span>
					</span>
				]}
				text={`An intervention program designed to provide targeted instruction and practice for ${level} students not yet reading on grade level.`}
			/>
			<Row center middle className="mb-8">
				<Col width="w-full lg:w-7/12" className="mb-6 lg:mb-0">
					<button onClick={() => setIsModalOpen(true)}>
						{level === 'elementary' ? (
							<StaticImage
								imgClassName="rounded-lg"
								src="../../../static/images/intervention/daily-lesson-structure-elementary.png"
								alt="daily lesson structure graph"
							/>
						) : (
							<StaticImage
								imgClassName="rounded-lg"
								src="../../../static/images/intervention/daily-lesson-structure-secondary.png"
								alt="daily lesson structure graph"
							/>
						)}
					</button>
				</Col>
				<Col width="w-full lg:w-5/12">
					<div className="rounded-lg bg-gray-100 p-6">
						<span className="mb-2 block text-2xl font-semibold">Physical and digital resources work together to help educators:</span>
						<ul className="list-checkmark pl-10 text-lg text-slate-600">
							<li className="mb-3">Teach all students high-utility skills/strategies for handling any text.</li>
							<li className="mb-3">Provide explicit, systematic instruction in the specific skills each reader needs.</li>
							<li>Ensure essential reading practice to expand vocabulary and background knowledge.</li>
						</ul>
					</div>
				</Col>
			</Row>
			<Row center middle>
				<div className="grid grid-cols-6 gap-8 px-6 pb-8 md:grid-cols-3">
					<div className="col-span-3 flex flex-col md:col-span-1">
						<div className="rounded-t-lg bg-gray-100">
							<div className="p-4">
								<Resource maxHeight={250} title={`ARC Accelerator | ${level.charAt(0).toUpperCase() + level.slice(1)}`} noLink noZoom />
							</div>
							<div className="flex flex-grow items-center justify-center rounded-b-lg bg-white p-4 shadow-lg">
								<span className="text-xl font-semibold">Teacher Guide</span>
							</div>
						</div>
					</div>

					<div className="col-span-3 flex flex-col md:col-span-1">
						<div className="rounded-t-lg bg-gray-100">
							<div className="flex max-h-[250px] p-4">
								<Resource title="IRLA" noLink noZoom />
								<div className="-ml-28">
									<Resource title="MLL Guide" noLink noZoom />
								</div>
							</div>

							<div className="flex flex-grow items-center justify-center rounded-b-lg bg-white p-4 shadow-lg">
								<span className="text-xl font-semibold">IRLA Framework & Guide to IRLA Coaching with Multilingual Learners</span>
							</div>
						</div>
					</div>

					<div className="col-span-3 flex flex-col md:col-span-1">
						<div className="rounded-t-lg bg-gray-100">
							<div className="flex min-h-[250px] items-center justify-center">
								<StaticImage src="../../../static/images/intervention/arc-reads.png" placeholder="blurred" alt="arc accelerator" objectFit="contain" />
							</div>

							<div className="flex flex-grow items-center justify-center rounded-b-lg bg-white p-4 shadow-lg">
								<span className="text-xl font-semibold">SchoolPace & ARC Reads Digital Logbook</span>
							</div>
						</div>
					</div>

					<div className="col-span-3 flex flex-col">
						<div className="rounded-t-lg bg-gray-100">
							<div className="flex p-4">
								{wideReading.edges
									.sort((a, b) => {
										const colorLevelA = extractColorLevel(a.node.title, level === 'secondary' ? true : false)
										const colorLevelB = extractColorLevel(b.node.title, level === 'secondary' ? true : false)
										const orderA = toolkitProperties[colorLevelA as keyof typeof toolkitProperties].order || 0
										const orderB = toolkitProperties[colorLevelB as keyof typeof toolkitProperties].order || 0
										return orderA - orderB
									})
									.map(({ node }) => {
										return (
											<Col key={node.title} width="w-1/6" gutter="none">
												<GatsbyImage image={node.gatsbyImageData} alt={node.title} />
											</Col>
										)
									})}
							</div>

							<div className="flex flex-grow items-center justify-center rounded-b-lg bg-white p-4 shadow-lg">
								<span className="text-xl font-semibold">Hook Book Library</span>
							</div>
						</div>
					</div>

					<div className="col-span-3 flex flex-col">
						<div className="rounded-t-lg bg-gray-100">
							<div className="flex p-4">
								{toolkits.edges
									.sort((a, b) => {
										const orderA = toolkitProperties[a.node.name.slice(-2) as keyof typeof toolkitProperties].order
										const orderB = toolkitProperties[b.node.name.slice(-2) as keyof typeof toolkitProperties].order
										return orderA - orderB
									})
									.map(({ node }) => {
										return (
											<Col key={node.name} width="w-1/3 md:w-1/6" gutter="none">
												<GatsbyImage image={node.images[0].gatsbyImageData} alt={node.name} />
											</Col>
										)
									})}
							</div>

							<div className="flex flex-grow items-center justify-center rounded-b-lg bg-white p-4 shadow-lg">
								<span className="text-xl font-semibold">IRLA Toolkits</span>
							</div>
						</div>
					</div>
				</div>
			</Row>
			<Modal wide title="Daily Lesson Structure" isOpen={isModalOpen} handleClose={() => setIsModalOpen(false)}>
				{level === 'elementary' ? (
					<StaticImage imgClassName="rounded-lg" src="../../../static/images/intervention/daily-lesson-structure-elementary.png" alt="daily lesson structure graph" />
				) : (
					<StaticImage imgClassName="rounded-lg" src="../../../static/images/intervention/daily-lesson-structure-secondary.png" alt="daily lesson structure graph" />
				)}
			</Modal>
		</Section>
	)
}
