import { Button } from '@arckit/ui'
import { Col, Row, Section } from 'components/grid'
import Layout from 'components/Layout'
import SubNav from 'components/SubNav'
import { graphql, useStaticQuery } from 'gatsby'
import { IGatsbyImageData, StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { H1 } from 'components/typography/Headings'
import { P } from 'components/typography/Paragraph'
import GetStartedCTA from 'components/forms/GetStartedCTA'
import SectionTitle from 'components/global/SectionTitle'
import ToolkitTabs from 'components/intervention/new_ToolkitTabs'
import Accelerator from 'components/intervention/new_Accelerator'

export default function LibrariesPage() {
	const data = useStaticQuery<ToolkitQuery>(query)

	const { fstk, secondary, irla, enil, wideReadingElementary, toolkitsElementary, wideReading, toolkits, accelerator, arcReads } = data

	const subNavItems = [
		{ name: 'ARC Accelerator Elementary', link: 'elementary' },
		{ name: 'Foundational Skills Toolkits', link: 'foundational-skills-toolkits' },
		{ name: 'ENIL Toolkits', link: 'enil-toolkits' },
		{ name: 'IRLA Toolkits', link: 'irla-toolkits' },
		{ name: 'ARC Accelerator Secondary', link: 'secondary' },
		{ name: 'IRLA Toolkits for Secondary Grades', link: 'irla-toolkits-secondary' }
	]

	function goToCta() {
		const input = document.getElementById('get-started-cta-email')
		input?.focus()
	}

	return (
		<Layout pageName="Toolkits">
			<SubNav items={subNavItems} />
			<Section className="overflow-hidden pt-10 pb-0">
				<div className="absolute top-0 h-full w-full bg-white">
					<div className="rainbow-gradient-light absolute right-0 bottom-0 top-auto h-[250px] w-[250px] rounded-[30%_70%_71%_29%_/_30%_30%_70%_70%] opacity-50 blur-[60px] sm:block md:bottom-auto md:top-8 md:h-[500px] md:w-[500px]"></div>
					<div className="rainbow-gradient-light absolute left-0 right-auto -top-[110px] hidden h-[300px] w-[300px] -translate-x-[50%] translate-y-[20%] rounded-full opacity-50 blur-[80px] sm:block"></div>
				</div>
				<Row middle className="relative">
					<Col width="w-full md:w-1/2">
						<H1 className="mb-4">
							Intervention <br /> Aligned to the
							<br />
							<span className="relative leading-relaxed text-ab-100 after:absolute after:-bottom-8 after:left-0 after:h-8 after:w-full after:rounded-[50%] after:border-[5px] after:border-transparent after:border-t-core-y sm:leading-normal">
								Science of Reading
							</span>
						</H1>
						<P className="mb-8">
							ARC's differentiated instructional tools give teachers a pathway to help every student boost their skills, confidence, and academic success.
						</P>
						<div className="mb-8 flex items-center gap-6">
							<Button onClick={goToCta} size="xl">
								Unlock Reading Success
							</Button>
						</div>
					</Col>
					<Col width="w-1/2" className="hidden self-end md:block">
						<StaticImage loading="eager" src="../../static/images/intervention/hero2.png" alt="girl reading roberto clemente book" />
					</Col>
				</Row>
			</Section>
			<Accelerator level="elementary" toolkits={toolkitsElementary} wideReading={wideReadingElementary} />
			<Section className="mt-20 md:mt-40" id="foundational-skills-toolkits" scrollMarginTop="75px">
				<SectionTitle
					title="Foundational Skills Toolkits"
					text="Tailor your tutoring with phonics, phonological & phonemic awareness, encoding/decoding, word study, fluency, and spelling for individuals and small groups."
				/>
				<Row>
					<Col>
						<ToolkitTabs toolkits={fstk} />
					</Col>
				</Row>
			</Section>
			<Section className="mt-20 md:mt-40" id="enil-toolkits" scrollMarginTop="75px">
				<SectionTitle
					title="ENIL Toolkits (Herramientas ENIL)"
					text="Strengthen Spanish literacy with specialized phonics, fluency, and spelling for individuals and small-groups."
				/>
				<Row>
					<Col>
						<ToolkitTabs toolkits={enil} />
					</Col>
				</Row>
			</Section>
			<Section className="mt-20 md:mt-40" id="irla-toolkits" scrollMarginTop="75px">
				<SectionTitle title="IRLA Toolkits" text="Deliver targeted intervention of key literacy skills to intermediate grade students." />
				<Row>
					<Col>
						<ToolkitTabs toolkits={irla} />
					</Col>
				</Row>
			</Section>
			<Accelerator level="secondary" toolkits={toolkits} wideReading={wideReading} />
			<Section className="mt-20 md:mt-40" id="irla-toolkits-secondary" scrollMarginTop="75px">
				<SectionTitle
					title="IRLA Toolkits for Secondary Grades"
					text="Build knowledge, reading skills, and confidence with age-appropriate, high-interest texts and conceptually-linked lessons."
				/>
				<Row>
					<Col>
						<ToolkitTabs toolkits={secondary} />
					</Col>
				</Row>
			</Section>
			<Section margin="mt-20 md:mt-40">
				<Row middle overflow={false}>
					<Col width="w-11/12 sm:w-3/5 xl:w-2/5" className="relative z-0 mx-auto mb-8">
						<GetStartedCTA
							pageName="Intervention Page"
							subHeading="Ready. Set. Read."
							heading="Start Building Your Classroom Libraries"
							underline="Classroom Libraries"
							copy="Choose from dozens of content-rich themes your students will love."
						/>
						<span className="absolute -bottom-6 -left-6 z-[-1] hidden sm:inline">
							<svg width="107" height="134" viewBox="0 0 107 134" fill="none" xmlns="http://www.w3.org/2000/svg">
								<circle cx="104.999" cy="132" r="1.66667" transform="rotate(180 104.999 132)" fill="#d1d5db" />
								<circle cx="104.999" cy="117.333" r="1.66667" transform="rotate(180 104.999 117.333)" fill="#d1d5db" />
								<circle cx="104.999" cy="102.667" r="1.66667" transform="rotate(180 104.999 102.667)" fill="#d1d5db" />
								<circle cx="104.999" cy="88" r="1.66667" transform="rotate(180 104.999 88)" fill="#d1d5db" />
								<circle cx="104.999" cy="73.3333" r="1.66667" transform="rotate(180 104.999 73.3333)" fill="#d1d5db" />
								<circle cx="104.999" cy="45" r="1.66667" transform="rotate(180 104.999 45)" fill="#d1d5db" />
								<circle cx="104.999" cy="16" r="1.66667" transform="rotate(180 104.999 16)" fill="#d1d5db" />
								<circle cx="104.999" cy="59" r="1.66667" transform="rotate(180 104.999 59)" fill="#d1d5db" />
								<circle cx="104.999" cy="30.6666" r="1.66667" transform="rotate(180 104.999 30.6666)" fill="#d1d5db" />
								<circle cx="104.999" cy="1.66665" r="1.66667" transform="rotate(180 104.999 1.66665)" fill="#d1d5db" />
								<circle cx="90.3333" cy="132" r="1.66667" transform="rotate(180 90.3333 132)" fill="#d1d5db" />
								<circle cx="90.3333" cy="117.333" r="1.66667" transform="rotate(180 90.3333 117.333)" fill="#d1d5db" />
								<circle cx="90.3333" cy="102.667" r="1.66667" transform="rotate(180 90.3333 102.667)" fill="#d1d5db" />
								<circle cx="90.3333" cy="88" r="1.66667" transform="rotate(180 90.3333 88)" fill="#d1d5db" />
								<circle cx="90.3333" cy="73.3333" r="1.66667" transform="rotate(180 90.3333 73.3333)" fill="#d1d5db" />
								<circle cx="90.3333" cy="45" r="1.66667" transform="rotate(180 90.3333 45)" fill="#d1d5db" />
								<circle cx="90.3333" cy="16" r="1.66667" transform="rotate(180 90.3333 16)" fill="#d1d5db" />
								<circle cx="90.3333" cy="59" r="1.66667" transform="rotate(180 90.3333 59)" fill="#d1d5db" />
								<circle cx="90.3333" cy="30.6666" r="1.66667" transform="rotate(180 90.3333 30.6666)" fill="#d1d5db" />
								<circle cx="90.3333" cy="1.66665" r="1.66667" transform="rotate(180 90.3333 1.66665)" fill="#d1d5db" />
								<circle cx="75.6654" cy="132" r="1.66667" transform="rotate(180 75.6654 132)" fill="#d1d5db" />
								<circle cx="31.9993" cy="132" r="1.66667" transform="rotate(180 31.9993 132)" fill="#d1d5db" />
								<circle cx="75.6654" cy="117.333" r="1.66667" transform="rotate(180 75.6654 117.333)" fill="#d1d5db" />
								<circle cx="31.9993" cy="117.333" r="1.66667" transform="rotate(180 31.9993 117.333)" fill="#d1d5db" />
								<circle cx="75.6654" cy="102.667" r="1.66667" transform="rotate(180 75.6654 102.667)" fill="#d1d5db" />
								<circle cx="31.9993" cy="102.667" r="1.66667" transform="rotate(180 31.9993 102.667)" fill="#d1d5db" />
								<circle cx="75.6654" cy="88" r="1.66667" transform="rotate(180 75.6654 88)" fill="#d1d5db" />
								<circle cx="31.9993" cy="88" r="1.66667" transform="rotate(180 31.9993 88)" fill="#d1d5db" />
								<circle cx="75.6654" cy="73.3333" r="1.66667" transform="rotate(180 75.6654 73.3333)" fill="#d1d5db" />
								<circle cx="31.9993" cy="73.3333" r="1.66667" transform="rotate(180 31.9993 73.3333)" fill="#d1d5db" />
								<circle cx="75.6654" cy="45" r="1.66667" transform="rotate(180 75.6654 45)" fill="#d1d5db" />
								<circle cx="31.9993" cy="45" r="1.66667" transform="rotate(180 31.9993 45)" fill="#d1d5db" />
								<circle cx="75.6654" cy="16" r="1.66667" transform="rotate(180 75.6654 16)" fill="#d1d5db" />
								<circle cx="31.9993" cy="16" r="1.66667" transform="rotate(180 31.9993 16)" fill="#d1d5db" />
								<circle cx="75.6654" cy="59" r="1.66667" transform="rotate(180 75.6654 59)" fill="#d1d5db" />
								<circle cx="31.9993" cy="59" r="1.66667" transform="rotate(180 31.9993 59)" fill="#d1d5db" />
								<circle cx="75.6654" cy="30.6666" r="1.66667" transform="rotate(180 75.6654 30.6666)" fill="#d1d5db" />
								<circle cx="31.9993" cy="30.6666" r="1.66667" transform="rotate(180 31.9993 30.6666)" fill="#d1d5db" />
								<circle cx="75.6654" cy="1.66665" r="1.66667" transform="rotate(180 75.6654 1.66665)" fill="#d1d5db" />
								<circle cx="31.9993" cy="1.66665" r="1.66667" transform="rotate(180 31.9993 1.66665)" fill="#d1d5db" />
								<circle cx="60.9993" cy="132" r="1.66667" transform="rotate(180 60.9993 132)" fill="#d1d5db" />
								<circle cx="17.3333" cy="132" r="1.66667" transform="rotate(180 17.3333 132)" fill="#d1d5db" />
								<circle cx="60.9993" cy="117.333" r="1.66667" transform="rotate(180 60.9993 117.333)" fill="#d1d5db" />
								<circle cx="17.3333" cy="117.333" r="1.66667" transform="rotate(180 17.3333 117.333)" fill="#d1d5db" />
								<circle cx="60.9993" cy="102.667" r="1.66667" transform="rotate(180 60.9993 102.667)" fill="#d1d5db" />
								<circle cx="17.3333" cy="102.667" r="1.66667" transform="rotate(180 17.3333 102.667)" fill="#d1d5db" />
								<circle cx="60.9993" cy="88" r="1.66667" transform="rotate(180 60.9993 88)" fill="#d1d5db" />
								<circle cx="17.3333" cy="88" r="1.66667" transform="rotate(180 17.3333 88)" fill="#d1d5db" />
								<circle cx="60.9993" cy="73.3333" r="1.66667" transform="rotate(180 60.9993 73.3333)" fill="#d1d5db" />
								<circle cx="17.3333" cy="73.3333" r="1.66667" transform="rotate(180 17.3333 73.3333)" fill="#d1d5db" />
								<circle cx="60.9993" cy="45" r="1.66667" transform="rotate(180 60.9993 45)" fill="#d1d5db" />
								<circle cx="17.3333" cy="45" r="1.66667" transform="rotate(180 17.3333 45)" fill="#d1d5db" />
								<circle cx="60.9993" cy="16" r="1.66667" transform="rotate(180 60.9993 16)" fill="#d1d5db" />
								<circle cx="17.3333" cy="16" r="1.66667" transform="rotate(180 17.3333 16)" fill="#d1d5db" />
								<circle cx="60.9993" cy="59" r="1.66667" transform="rotate(180 60.9993 59)" fill="#d1d5db" />
								<circle cx="17.3333" cy="59" r="1.66667" transform="rotate(180 17.3333 59)" fill="#d1d5db" />
								<circle cx="60.9993" cy="30.6666" r="1.66667" transform="rotate(180 60.9993 30.6666)" fill="#d1d5db" />
								<circle cx="17.3333" cy="30.6666" r="1.66667" transform="rotate(180 17.3333 30.6666)" fill="#d1d5db" />
								<circle cx="60.9993" cy="1.66665" r="1.66667" transform="rotate(180 60.9993 1.66665)" fill="#d1d5db" />
								<circle cx="17.3333" cy="1.66665" r="1.66667" transform="rotate(180 17.3333 1.66665)" fill="#d1d5db" />
								<circle cx="46.3333" cy="132" r="1.66667" transform="rotate(180 46.3333 132)" fill="#d1d5db" />
								<circle cx="2.66536" cy="132" r="1.66667" transform="rotate(180 2.66536 132)" fill="#d1d5db" />
								<circle cx="46.3333" cy="117.333" r="1.66667" transform="rotate(180 46.3333 117.333)" fill="#d1d5db" />
								<circle cx="2.66536" cy="117.333" r="1.66667" transform="rotate(180 2.66536 117.333)" fill="#d1d5db" />
								<circle cx="46.3333" cy="102.667" r="1.66667" transform="rotate(180 46.3333 102.667)" fill="#d1d5db" />
								<circle cx="2.66536" cy="102.667" r="1.66667" transform="rotate(180 2.66536 102.667)" fill="#d1d5db" />
								<circle cx="46.3333" cy="88" r="1.66667" transform="rotate(180 46.3333 88)" fill="#d1d5db" />
								<circle cx="2.66536" cy="88" r="1.66667" transform="rotate(180 2.66536 88)" fill="#d1d5db" />
								<circle cx="46.3333" cy="73.3333" r="1.66667" transform="rotate(180 46.3333 73.3333)" fill="#d1d5db" />
								<circle cx="2.66536" cy="73.3333" r="1.66667" transform="rotate(180 2.66536 73.3333)" fill="#d1d5db" />
								<circle cx="46.3333" cy="45" r="1.66667" transform="rotate(180 46.3333 45)" fill="#d1d5db" />
								<circle cx="2.66536" cy="45" r="1.66667" transform="rotate(180 2.66536 45)" fill="#d1d5db" />
								<circle cx="46.3333" cy="16" r="1.66667" transform="rotate(180 46.3333 16)" fill="#d1d5db" />
								<circle cx="2.66536" cy="16" r="1.66667" transform="rotate(180 2.66536 16)" fill="#d1d5db" />
								<circle cx="46.3333" cy="59" r="1.66667" transform="rotate(180 46.3333 59)" fill="#d1d5db" />
								<circle cx="2.66536" cy="59" r="1.66667" transform="rotate(180 2.66536 59)" fill="#d1d5db" />
								<circle cx="46.3333" cy="30.6666" r="1.66667" transform="rotate(180 46.3333 30.6666)" fill="#d1d5db" />
								<circle cx="2.66536" cy="30.6666" r="1.66667" transform="rotate(180 2.66536 30.6666)" fill="#d1d5db" />
								<circle cx="46.3333" cy="1.66665" r="1.66667" transform="rotate(180 46.3333 1.66665)" fill="#d1d5db" />
								<circle cx="2.66536" cy="1.66665" r="1.66667" transform="rotate(180 2.66536 1.66665)" fill="#d1d5db" />
							</svg>
						</span>
						<span className="absolute -top-10 -right-6 z-[-1] hidden sm:inline">
							<svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" clipRule="evenodd" d="M0 100C0 44.7715 0 0 0 0C55.2285 0 100 44.7715 100 100C100 100 100 100 0 100Z" fill="#0271BC"></path>
							</svg>
						</span>
					</Col>
				</Row>
			</Section>
		</Layout>
	)
}

interface ToolkitImage {
	id: string
	gatsbyImageData: IGatsbyImageData
}

interface ImageWithTitleDescriptionAndGatsbyImage {
	title: string
	description?: string
	gatsbyImageData: IGatsbyImageData
}

export interface Toolkit {
	id: string
	colorLevel: keyof typeof toolkitProperties
	type: 'Foundational Skills' | 'IRLA' | 'Secondary' | 'ENIL'
	standard: 'CCSS' | 'TEKS'
	handbook: {
		title: string
		cover: ToolkitImage
	}
	scopeSequence: {
		title: string
		cover: ToolkitImage
	}
	chapterBooks?: ToolkitImage[]
	wordJournals?: ToolkitImage[]
	seriesBooks?: ToolkitImage[]
	bananagramasEnEspanol?: ToolkitImage[]
	genreCards?: ToolkitImage[]
	graphemeCards?: ToolkitImage[]
	smallGroupTexts?: ToolkitImage[]
	consonantPracticeCards?: ToolkitImage[]
	alphabetStrips?: ToolkitImage[]
	keyWordStrip?: ToolkitImage[]
	blendsAndDigraphsPracticeCards?: ToolkitImage[]
	powerWordKits?: ToolkitImage[]
	powerWordsRaceTrackGameSets?: ToolkitImage[]
	openSyllablesFlipBookletSet?: ToolkitImage[]
	keyWordFriezeBookmark?: ToolkitImage[]
	seriesauthorStudyTexts?: ToolkitImage[]
	soundSortingPictureCards?: ToolkitImage[]
	openSyllablesGameSet?: ToolkitImage[]
}

export interface ToolkitQueryNode {
	node: Toolkit
}

export interface ToolkitQueryEdge {
	edges: ToolkitQueryNode[]
}

export interface AcceleratorBasketQueryEdge {
	edges: { node: { title: string; gatsbyImageData: IGatsbyImageData } }[]
}

export interface AcceleratorToolkitQueryEdge {
	edges: { node: { name: string; images: { gatsbyImageData: IGatsbyImageData }[] } }[]
}

interface ToolkitQuery {
	fstk: ToolkitQueryEdge
	secondary: ToolkitQueryEdge
	irla: ToolkitQueryEdge
	enil: ToolkitQueryEdge
	accelerator: ImageWithTitleDescriptionAndGatsbyImage
	arcReads: ImageWithTitleDescriptionAndGatsbyImage
	wideReadingElementary: AcceleratorBasketQueryEdge
	toolkitsElementary: AcceleratorToolkitQueryEdge
	wideReading: AcceleratorBasketQueryEdge
	toolkits: AcceleratorToolkitQueryEdge
}

export const toolkitProperties = {
	Y: {
		order: 1,
		color: 'bg-core-y',
		name: 'Yellow',
		spanish_name: ''
	},
	A: {
		order: 1,
		color: 'bg-core-y',
		name: 'Amarillo',
		spanish_name: ''
	},
	'1G': {
		order: 2,
		color: 'bg-core-g',
		name: '1 Green',
		spanish_name: ''
	},
	V: {
		order: 2,
		color: 'bg-core-g',
		name: 'Verde',
		spanish_name: ''
	},
	'2G': {
		order: 3,
		color: 'bg-core-g',
		name: '2 Green',
		spanish_name: ''
	},
	T: {
		order: 3,
		color: 'bg-core-t',
		name: 'Turquesa',
		spanish_name: ''
	},
	'1B': {
		order: 4,
		color: 'bg-core-b',
		name: '1 Blue',
		spanish_name: ''
	},
	'1Az': {
		order: 4,
		color: 'bg-core-b',
		name: '1 Azul',
		spanish_name: ''
	},
	'2B': {
		order: 5,
		color: 'bg-core-b',
		name: '2 Blue',
		spanish_name: ''
	},
	'2Az': {
		order: 5,
		color: 'bg-core-b',
		name: '2 Azul',
		spanish_name: ''
	},
	'1R': {
		order: 6,
		color: 'bg-core-r',
		name: '1 Red',
		spanish_name: '1 Rojo'
	},
	'2R': {
		order: 7,
		color: 'bg-core-r',
		name: '2 Red',
		spanish_name: '2 Rojo'
	},
	Wt: {
		order: 8,
		color: 'bg-core-wt',
		name: 'White',
		spanish_name: ''
	},
	Bl: {
		order: 8,
		color: 'bg-core-wt',
		name: 'Blanco',
		spanish_name: ''
	},
	Bk: {
		order: 9,
		color: 'bg-black',
		name: 'Black',
		spanish_name: ''
	},
	Ne: {
		order: 9,
		color: 'bg-black',
		name: 'Negro',
		spanish_name: ''
	},
	Or: {
		order: 10,
		color: 'bg-core-or',
		name: 'Orange',
		spanish_name: ''
	},
	An: {
		order: 10,
		color: 'bg-core-or',
		name: 'Naranja',
		spanish_name: ''
	},
	Pu: {
		order: 11,
		color: 'bg-core-pu',
		name: 'Purple',
		spanish_name: ''
	},
	'1Br': {
		order: 12,
		color: 'bg-core-br',
		name: '1 Bronze',
		spanish_name: ''
	},
	'2Br': {
		order: 13,
		color: 'bg-core-br',
		name: '2 Bronze',
		spanish_name: ''
	},
	Si: {
		order: 13,
		color: 'bg-core-si',
		name: 'Silver',
		spanish_name: ''
	},
	Pl: {
		order: 13,
		color: 'bg-core-si',
		name: 'Plata',
		spanish_name: ''
	},
	Gl: {
		order: 14,
		color: 'bg-core-gl',
		name: 'Gold',
		spanish_name: ''
	}
}

const query = graphql`
	query {
		fstk: allContentfulToolkit(filter: { type: { eq: "Foundational Skills" } }) {
			edges {
				node {
					...toolkit
				}
			}
		}
		secondary: allContentfulToolkit(filter: { type: { eq: "Secondary" } }) {
			edges {
				node {
					...toolkit
				}
			}
		}
		irla: allContentfulToolkit(filter: { type: { eq: "IRLA" } }) {
			edges {
				node {
					...toolkit
				}
			}
		}
		enil: allContentfulToolkit(filter: { type: { eq: "ENIL" } }) {
			edges {
				node {
					...toolkit
				}
			}
		}
		accelerator: contentfulAsset(title: { eq: "ARC Accelerator Secondary | Framework | Cover" }) {
			title
			description
			gatsbyImageData(height: 218)
		}
		arcReads: contentfulAsset(title: { eq: "SchoolPace and ARC Reads" }) {
			title
			description
			gatsbyImageData
		}
		wideReadingElementary: allContentfulAsset(
			filter: {
				title: {
					in: [
						"Wide Reading | 1G Basket 1"
						"Wide Reading | 2G Basket 1"
						"Wide Reading | 1B Basket 1"
						"Wide Reading | 2B Basket 1"
						"Wide Reading | 1R Basket 1"
						"Wide Reading | 2R Basket 1"
						"Wide Reading | Wt Basket 1"
						"Wide Reading | Bk Basket 1"
					]
				}
			}
		) {
			edges {
				node {
					title
					gatsbyImageData
				}
			}
		}
		toolkitsElementary: allContentfulProduct(
			filter: {
				name: {
					in: [
						"IRLA Foundational Skills Toolkit 1G"
						"IRLA Foundational Skills Toolkit 2G"
						"IRLA Foundational Skills Toolkit 1B"
						"IRLA Foundational Skills Toolkit 2B"
						"IRLA Foundational Skills Toolkit 1R"
						"IRLA Foundational Skills Toolkit 2R"
						"IRLA Foundational Skills Toolkit 2R"
						"IRLA Toolkit Wt"
						"IRLA Toolkit Bk"
					]
				}
			}
		) {
			edges {
				node {
					name
					images {
						gatsbyImageData
					}
				}
			}
		}
		wideReading: allContentfulAsset(
			filter: {
				title: {
					in: [
						"1R ARC Accelerator | Secondary Hook Book Basket"
						"2R ARC Accelerator | Secondary Hook Book Basket"
						"Wt ARC Accelerator | Secondary Hook Book Basket"
						"Bk ARC Accelerator | Secondary Hook Book Basket"
						"Or ARC Accelerator | Secondary Hook Book Basket"
						"Pu ARC Accelerator | Secondary Hook Book Basket"
					]
				}
			}
		) {
			edges {
				node {
					title
					gatsbyImageData
				}
			}
		}
		toolkits: allContentfulProduct(
			filter: {
				name: {
					in: [
						"IRLA Toolkit for Secondary Grades 1R"
						"IRLA Toolkit for Secondary Grades 2R"
						"IRLA Toolkit for Secondary Grades Wt"
						"IRLA Toolkit for Secondary Grades Bk"
						"IRLA Toolkit Or"
						"IRLA Toolkit Pu"
					]
				}
			}
		) {
			edges {
				node {
					name
					images {
						gatsbyImageData
					}
				}
			}
		}
	}
`
