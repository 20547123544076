import MaterialButton from 'components/arc-core/MaterialButton'
import MaterialButtonBaskets from 'components/arc-core/MaterialButtonBaskets'
import Resource from 'components/global/Resource'
import { Col, Row } from 'components/grid'
import { H4, H5 } from 'components/typography/Headings'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Toolkit, ToolkitQueryEdge, ToolkitQueryNode, toolkitProperties } from 'pages/intervention'
import React from 'react'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'
import { twMerge } from 'tailwind-merge'

interface Props {
	toolkits: ToolkitQueryEdge
}

function shouldDisplayDecodables(toolkit: Toolkit) {
	if (toolkit.colorLevel === 'Or' || toolkit.colorLevel === 'Pu') return false
	else return true
}

function shouldDisplayDecodablesButton(toolkit: Toolkit) {
	if (toolkit.colorLevel === 'Or' || toolkit.colorLevel === 'Pu') return false
	else return true
}

export default function ToolkitTabs({ toolkits }: Props) {
	return (
		<Tabs selectedTabClassName="bg-gray-100 font-medium">
			<TabList className="flex overflow-x-auto">
				{toolkits.edges.sort(sortByColorLevel).map(({ node: t }) => {
					return (
						<Tab key={`${t.id}-tab`} className="min-w-max flex-grow cursor-pointer rounded-t border-t border-l border-r hover:bg-gray-100">
							<span className="block px-4 py-2 text-center">{t.colorLevel}</span>
							<div className={twMerge('h-1', toolkitProperties[t.colorLevel].color)} />
						</Tab>
					)
				})}
			</TabList>
			{toolkits.edges.sort(sortByColorLevel).map(({ node: t }) => {
				return (
					<TabPanel key={`${t.id}-panel`}>
						<span className="my-4 inline-block w-full text-center text-2xl font-semibold">
							The{' '}
							{t.type === 'ENIL' && toolkitProperties[t.colorLevel].spanish_name
								? toolkitProperties[t.colorLevel].spanish_name
								: toolkitProperties[t.colorLevel].name}{' '}
							module includes the following materials
						</span>
						<Row center className="pb-8">
							<div className="grid grid-cols-6 gap-8 px-6 md:grid-cols-3">
								<div className="col-span-3 flex flex-col md:col-span-1">
									<div className="rounded-t-lg bg-gray-100">
										<GatsbyImage
											image={t.handbook.cover.gatsbyImageData}
											alt="fstk handbook"
											className={twMerge('mx-auto mt-auto w-auto transform transition duration-200 ease-in-out')}
											style={{ maxHeight: 250 }}
											objectFit="contain"
										/>
									</div>
									<div className="flex flex-grow items-center justify-center rounded-b-lg bg-white p-4 shadow-lg">
										<span className="text-xl font-semibold">Handbook</span>
									</div>
								</div>
								<div className="col-span-3 flex flex-col md:col-span-1">
									<div className="rounded-t-lg bg-gray-100">
										<GatsbyImage
											image={t.scopeSequence.cover.gatsbyImageData}
											alt="fstk handbook"
											className={twMerge('mx-auto mt-auto w-auto transform transition duration-200 ease-in-out')}
											style={{ maxHeight: 250 }}
											objectFit="contain"
										/>
									</div>
									<div className="flex flex-grow items-center justify-center rounded-b-lg bg-white p-4 shadow-lg">
										<span className="text-center text-xl font-semibold">Scope & Sequence</span>
									</div>
								</div>
								{componentList
									.filter((comp) => t[comp.key] !== null)
									.map((c) => {
										return (
											<div key={`compoennt-${c.key}`} className="col-span-3 flex flex-col md:col-span-1">
												<div className="rounded-t-lg bg-gray-100">
													<GatsbyImage
														image={t[c.key]![0].gatsbyImageData}
														alt={c.title}
														className={twMerge('mx-auto mt-auto w-auto transform transition duration-200 ease-in-out')}
														style={{ maxHeight: 250 }}
														objectFit="contain"
													/>
												</div>
												<div className="item-center flex flex-grow items-center justify-center rounded-b-lg bg-white p-4 shadow-lg">
													<span className="text-xl font-semibold">{c.title}</span>
												</div>
											</div>
										)
									})}
								{t.smallGroupTexts && (
									<div className={twMerge('flex flex-col', determinBasketColumnWidth(t.smallGroupTexts.length))}>
										<div className="rounded-t-lg bg-gray-100 p-4">
											<div className="flex justify-center">
												{t.smallGroupTexts.map((text) => {
													return (
														<div className="max-w-max">
															<GatsbyImage
																image={text.gatsbyImageData}
																alt="basket full of books"
																style={{ maxHeight: 218, maxWidth: 205 }}
																objectFit="contain"
															/>
														</div>
													)
												})}
											</div>
										</div>
										<div className="item-center flex flex-grow flex-col items-center justify-center rounded-b-lg bg-white p-4 shadow-lg">
											<span className="text-xl font-semibold">{shouldDisplayDecodables(t) ? 'Decodable Texts' : 'Small-Group Texts'}</span>
											{shouldDisplayDecodablesButton(t) && (
												<div className="flex justify-center">
													<Resource title={t.type === 'ENIL' ? 'ARC Decodables | Spanish' : 'ARC Decodables'} linkOnly>
														<button className="max-w-xs rounded bg-core-y px-2 py-1 text-sm text-gray-800">Phonics Skills Chart</button>
													</Resource>
												</div>
											)}
										</div>
									</div>
								)}
							</div>
						</Row>
					</TabPanel>
				)
			})}
		</Tabs>
	)
}

function determinBasketColumnWidth(length: number) {
	if (length === 1) return 'col-span-full md:col-span-1'
	if (length === 2) return 'col-span-full md:col-span-1'
	if (length === 3) return 'col-span-full'
	else return 'col-span-full'
}

type KeysOfComponentList = keyof Toolkit

type FilteredKeys = Exclude<KeysOfComponentList, 'id' | 'colorLevel' | 'type' | 'standard' | 'handbook' | 'scopeSequence'>

const componentList: { key: FilteredKeys; title: string }[] = [
	{ key: 'chapterBooks', title: 'Chapter Books' },
	{ key: 'wordJournals', title: 'Word Journals' },
	{ key: 'seriesBooks', title: 'Chapter Books' },
	{ key: 'bananagramasEnEspanol', title: 'Bananagrams' },
	{ key: 'genreCards', title: 'Genre Cards' },
	{ key: 'graphemeCards', title: 'Grapheme Cards' },
	{ key: 'consonantPracticeCards', title: 'Initial Consonants Picture Cards' },
	{ key: 'alphabetStrips', title: 'Alphabet Strips' },
	{ key: 'blendsAndDigraphsPracticeCards', title: 'Initial Blends & Digraphs Picture Cards' },
	{ key: 'powerWordKits', title: 'Power Word Kit' },
	{ key: 'powerWordsRaceTrackGameSets', title: 'Power Words Race Track Game Set' },
	{ key: 'openSyllablesFlipBookletSet', title: 'Open Syllables Flip Booklet Set' },
	{ key: 'keyWordFriezeBookmark', title: 'Key Word Strip Bookmark' },
	{ key: 'keyWordStrip', title: 'Key Word Strips' },
	{ key: 'seriesauthorStudyTexts', title: 'Series/Author Study Texts' },
	{ key: 'soundSortingPictureCards', title: 'Sound Sorting Picture Cards' },
	{ key: 'openSyllablesGameSet', title: 'Open Syllables Game Set' }
]

function sortByColorLevel(a: ToolkitQueryNode, b: ToolkitQueryNode): number {
	const orderA = toolkitProperties[a.node.colorLevel].order
	const orderB = toolkitProperties[b.node.colorLevel].order
	return orderA - orderB
}
